import { useCallback } from 'react';

import { useEnv } from '@lib/components/envProvider';
import { GA_ActionKind, GA_PushPayload, GA_Target, analytics } from '@lib/services/googleAnalytics';

import { useLogger } from './useLogger';

const isDebug = false;

export const useAnalytics = () => {
  const { gaId } = useEnv();
  const log = useLogger('Analytics');
  const handleGetDataLayer = () => {
    return analytics.getDataLayer();
  };

  const handlePush = useCallback(
    (payload: GA_PushPayload) => {
      const dl = analytics.getDataLayer();

      if (gaId || !dl || !dl.push) {
        log.warn('ANALYTICS: not GA client found!');

        return;
      }

      if (process.env.APP_ENV !== 'prod') {
        if (isDebug) log.debug('ANALYTICS push:', JSON.stringify(payload, null, 2));
      }
      dl.push(payload);
    },
    [gaId, log]
  );

  const handleTrackEvent = useCallback(
    (target: GA_Target, action: GA_ActionKind, value?: any) => {
      handlePush({
        event: 'interaction',
        target,
        action,
        'target-properties': value
      });
    },
    [handlePush]
  );

  return {
    push: handlePush,
    trackEvent: handleTrackEvent,
    getDataLayer: handleGetDataLayer
  };
};
