import { useEnv } from '@lib/components/envProvider';
import { isServer } from '@lib/utils';

const getDataLayer = () => {
  if (isServer()) return;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { gaId } = useEnv();

  if (!gaId) return;

  return window.dataLayer;
};

const gaService = {
  getDataLayer
};

export { gaService as analytics };
