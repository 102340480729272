/* eslint-disable no-console */
export const useLogger = (prefix?: string) => {
  return {
    debug: (...params: any[]) => {
      if ((window as any)._env_?.env === 'prod') return;

      if (typeof params[0] === 'string' && prefix) {
        params[0] = `[${prefix}] ${params[0]}`;
      }
      console.log(...params);
    },
    log: (...params: any[]) => {
      if ((window as any)._env_?.env === 'prod') return;

      if (typeof params[0] === 'string' && prefix) {
        params[0] = `[${prefix}] ${params[0]}`;
      }
      console.log(...params);
    },
    warn: (...params: any[]) => {
      if ((window as any)._env_?.env === 'prod') return;

      if (typeof params[0] === 'string' && prefix) {
        params[0] = `[${prefix}] ${params[0]}`;
      }

      console.warn(...params);
    },
    error: (...params: any[]) => {
      if ((window as any)._env_?.env === 'prod') return;

      if (typeof params[0] === 'string' && prefix) {
        params[0] = `[${prefix}] ${params[0]}`;
      }
      console.error(...params);
    }
  };
};
